var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3 is-centered"},[_c('UIImage',{attrs:{"src":_vm.showImage}}),_c('b-upload',{staticClass:"upload is-inline-block",nativeOn:{"change":function($event){return _vm.submitPicture.apply(null, arguments)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('p',{staticClass:"mt-3 mb-3 has-text-centered has-text-weight-bold content is-medium upload-link"},[_vm._v(" Alterar Foto ")])]),_c('p',{staticClass:"content is-small has-text-centered"},[_vm._v(" Esta foto será utilizada em sua carteirinha de estudante e poderá ser acessada pelo seu professor. ")])],1),_c('div',{staticClass:"column is-offset-2 is-7"},[_c('form',{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handlePersonalDataSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"email","label":"E-mail","placeholder":"E-mail","type":"email","validation":_vm.$v.form.email,"error":{
								required: '*Campo obrigatório.',
								email: '*Email no formato inválido.'
							},"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"id":"celular","label":"Celular","type":"phone","minlength":11,"placeholder":"Celular","validation":_vm.$v.form.celular,"error":{
								required: '*Campo obrigatório.',
								minLength: '*Número de celular inválido.',
							},"required":""},model:{value:(_vm.form.celular),callback:function ($$v) {_vm.$set(_vm.form, "celular", $$v)},expression:"form.celular"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"pais-formacao-superior","type":"select","options":[
								{ label: 'Sim', value: 'sim' },
								{ label: 'Não', value: 'nao' },
							],"label":"O seus pais possuem formação superior?","placeholder":"Selecione"},model:{value:(_vm.form.paisPossuemFormacao),callback:function ($$v) {_vm.$set(_vm.form, "paisPossuemFormacao", $$v)},expression:"form.paisPossuemFormacao"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"empresa","label":"Empresa","placeholder":"Empresa"},model:{value:(_vm.form.empresa),callback:function ($$v) {_vm.$set(_vm.form, "empresa", $$v)},expression:"form.empresa"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"cargo","label":"Cargo","placeholder":"Cargo"},model:{value:(_vm.form.cargo),callback:function ($$v) {_vm.$set(_vm.form, "cargo", $$v)},expression:"form.cargo"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"url-linkedin","label":"URL Linkedin","placeholder":"URL Linkedin"},model:{value:(_vm.form.urlLinkedin),callback:function ($$v) {_vm.$set(_vm.form, "urlLinkedin", $$v)},expression:"form.urlLinkedin"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"url-facebook","label":"URL Facebook","placeholder":"URL Facebook"},model:{value:(_vm.form.urlFacebook),callback:function ($$v) {_vm.$set(_vm.form, "urlFacebook", $$v)},expression:"form.urlFacebook"}})],1),_c('div',{staticClass:"column is-12"},[_c('UIInput',{attrs:{"id":"cor-raca","label":"Cor e Raça","placeholder":"Selecione","type":"select","options":_vm.corRacas},model:{value:(_vm.form.corRaca),callback:function ($$v) {_vm.$set(_vm.form, "corRaca", $$v)},expression:"form.corRaca"}})],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-5 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded is-outlined",attrs:{"type":"button"},on:{"click":_vm.handleCancel}},[_vm._v(" Cancelar ")])]),_c('div',{staticClass:"column is-7 has-text-centered mt-4"},[_c('button',{staticClass:"button is-primary is-rounded",attrs:{"type":"submit"}},[_vm._v(" Atualizar dados ")])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }